<template>
  <BaseModal
    name="modal-ticket"
    size="lg"
    title="Gerar Ticket"
    @hidden="closeModal"
    @shown="openModal"
  >
    <!-- Usuário seleciona se é do tipo comercial ou não -->
    <div
      data-anima="top"
      class="set-fiscal"
      v-if="
        nota_dados.status == 'NOT_ISSUED' ||
        nota_dados.status == 'ERROR' ||
        openGeration
      "
    >
      <b-form-group label="Seu produto é do tipo Comercial " label-for="codigo">
        <b-form-checkbox v-model="comercial" name="check-button" switch>
        </b-form-checkbox>
      </b-form-group>
      <BaseButton
        variant="outline-info"
        class="mr-3"
        @click="addFiscal"
        v-if="!fiscal && !openGeration"
      >
        Adicionar Nota Fiscal
      </BaseButton>

      <BaseButton
        variant="black"
        class="mr-3 mb-3"
        v-if="openGeration"
        @click="generateFiscal"
      >
        Gerar Nota Fiscal
      </BaseButton>

      <BaseButton
        variant="link-info"
        class="mr-3"
        v-if="fiscal"
        @click="checkStatusFiscal"
      >
        Atualizar Status da NF
      </BaseButton>
    </div>

    <!-- Envios do tipo comercial precisam da chave da nota fiscal -->
    <div data-anima="top" v-if="comercial">
      <b-form-group
        v-if="fiscal && selectIntegration"
        class="mt-2"
        label="Selecione a plataforma que deseja emitir a Chave da Nota Fiscal"
        label-for="codigo"
      >
        <b-row class="mt-3 center-itens">
          <!-- <b-col cols="12" md="3" class="mr-1 mb-4">
            <div>
              <b-card
                img-alt="Image"
                img-top
                tag="article"
                class="mb-2 custom-width-card"
                body-class="pl-0 pr-0 size-card-smartnotas"
                @click="emitFiscal('SmartNotas')"
                v-b-tooltip="{
                  title: 'SmartNotas',
                  placement: 'top',
                }"
              >
                <b-card-text class="container-logo">
                  <img src="@/assets/icons/smartNotas.svg" alt="" />
                </b-card-text>
              </b-card>
            </div>
          </b-col> -->
          <b-col cols="12" md="3" class="ml-1">
            <div>
              <b-card
                img-alt="Image"
                img-top
                tag="article"
                class="mb-2 custom-width-card"
                body-class="pl-0 pr-0"
                @click="emitFiscal('NotaZZ')"
                v-b-tooltip="{
                  title: 'NotaZZ',
                  placement: 'top',
                }"
              >
                <b-card-text class="container-logo">
                  <img src="@/assets/icons/notaZZ.svg" alt="" />
                </b-card-text>
              </b-card>
            </div>
          </b-col>
          <!-- <b-col cols="12" md="3" class="ml-2">
            <div>
              <b-card
                img-alt="Image"
                img-top
                tag="article"
                class="custom-width-card"
                body-class="pl-0 pr-0"
                @click="emitFiscal('Enotas')"
                v-b-tooltip="{
                  title: 'Enotas',
                  placement: 'top',
                }"
              >
                <b-card-text class="container-logo">
                  <img width="60" src="@/assets/icons/enotas.png" alt="" />
                </b-card-text>
              </b-card>
            </div>
          </b-col> -->
        </b-row>
      </b-form-group>
      <b-form-group label="Chave da Nota Fiscal " label-for="codigo">
        <b-form-input
          v-model="notaFiscal"
          v-mask="'#### #### #### #### #### #### #### #### #### #### ####'"
          placeholder="Chave da Nota Fiscal"
          name="codigo"
        ></b-form-input>
      </b-form-group>
    </div>

    <!-- Selecionar Frete -->
    <div
      data-anima="top"
      class="opcoes-frete"
      v-if="options_shipping.length && !shipping_selected && !loading"
    >
      <legend v-if="!shipping_selected && !loading">Selecione o Frete</legend>
      <b-form-group label="" v-slot="{ ariaDescribedby }">
        <div
          class="frete-item"
          v-for="frete in options_shipping"
          :key="frete.id"
        >
          <b-form-radio
            :aria-describedby="ariaDescribedby"
            name="frete"
            :value="!frete.error ? frete.id : null"
            v-model="shipping.service_id"
            @change="changeFrete(frete)"
            :disabled="frete.error ? true : false"
          >
            <div class="infos-frete" v-if="!frete.error">
              <img
                v-if="frete.company && frete.company.picture"
                :src="frete.company.picture"
              />
              <div class="info-envio">
                <span>
                  {{ frete.name }}
                </span>
                <span v-if="frete.delivery_range">
                  {{ frete.delivery_range.min }} à
                  {{ frete.delivery_range.max }} dias úteis
                </span>
                <span class="frete-valor">
                  R$ {{ formatMoney(+frete.price) }}
                </span>
              </div>
            </div>
            <div class="infos-frete" v-else style="width: 100%">
              <img
                v-if="frete.company && frete.company.picture"
                :src="frete.company.picture"
              />
              <div class="info-envio-error" style="">
                <span>
                  {{ frete.name }}
                </span>
                <span>
                  {{ frete.error }}
                </span>
              </div>
            </div>
          </b-form-radio>
        </div>
      </b-form-group>
    </div>
    <div
      data-anima="top"
      class="opcoes-frete"
      v-if="!options_shipping.length && !shipping_selected && !loading"
    >
      <b-row class="Table-body justify-content-center">
        <p class="nao-encontrado">Nenhuma transportadora foi encontrada</p>
      </b-row>
    </div>

    <div
      data-anima="top"
      class="opcoes-frete"
      v-if="shipping_selected && !loading"
    >
      <b-row class="Table-body justify-content-center">
        <legend>Frete Selecionado</legend>
        <div class="frete-item" style="width: 100%">
          <div class="infos-frete" style="width: 100%">
            <img
              v-if="
                shipping_selected.frete.company &&
                shipping_selected.frete.company.picture
              "
              :src="shipping_selected.frete.company.picture"
            />
            <div class="info-envio-error" style="">
              <span>
                {{ shipping_selected.frete.name }}
              </span>
              <span>
                {{ shipping_selected.frete.delivery_range.min }} à
                {{ shipping_selected.frete.delivery_range.max }} dias úteis
              </span>
              <span>
                {{ shipping_selected.frete.error }}
              </span>
            </div>
          </div>
        </div>
      </b-row>
    </div>

    <!-- CNAE - Quando for Latam Cargo pedir o código CNAE -->
    <div
      data-anima="top"
      v-if="
        shipping &&
        shipping.frete &&
        shipping.frete.company.name === 'Latam Cargo' &&
        !loading
      "
    >
      <b-form-group label="Código CNAE" label-for="codigo">
        <b-form-input
          v-model="codigoCNAE"
          placeholder="Código CNAE"
        ></b-form-input>
      </b-form-group>
    </div>

    <!-- Quando for JadLog mostrar select de Agencia -->
    <div data-anima="top" v-if="jadlog && !loading">
      <b-form-group label="Selecione uma Agência" label-for="agencia">
        <b-form-select
          v-model="selectedAgencia"
          value-field="id"
          text-field="name"
          :options="optionsAgencia"
          v-validate="'required'"
          name="agencia"
        ></b-form-select>
        <b-form-invalid-feedback :state="!errors.has('agencia')">
          Por favor, selecione a agência.
        </b-form-invalid-feedback>
      </b-form-group>
    </div>

    <b-row
      class="Table-body justify-content-center"
      v-if="nota_dados.status != 'NOT_ISSUED' || nota_dados.status != 'ERROR'"
    >
      <p class="nao-encontrado" v-if="nota_dados.status == 'PROCESSING'">
        Nota Fiscal em processamento. <br /><br />Por favor aguarde
      </p>

      <p class="nao-encontrado" v-if="nota_dados.status == 'ISSUED_EXTERNALLY'">
        Nota Fiscal emitida externamente.
      </p>
      <p class="nao-encontrado" v-if="nota_dados.status == 'ISSUED'">
        ✨ Nota Fiscal emitida com sucesso

        <br />
        <br />
        <!-- {{ nota_dados }} -->

        Emissão: {{ formatDate(nota_dados.emitted_at) }}
        <br />
        <br />

        Integração: {{ nota_dados.issued_by_integration }}
        <br />
        <br />

        Código da NF: {{ nota_dados.key_note }}
      </p>
    </b-row>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-3" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton
        :disabled="
          (nota_dados.status == 'NOT_ISSUED' ||
          nota_dados.status == 'PROCESSING' ||
          nota_dados.status == 'ERROR')
        "
        variant="primary"
        class="mr-3"
        @click="gerarEtiqueta"
      >
        Gerar Etiqueta
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import moment from "moment";
import Money from "@/mixins/money";
import BaseModal from "@/components/base/Modal";
// import ConnectionsService from "@/services/resources/ConnectionsService";
// const service = ConnectionsService.build();

import OrderService from "@/services/resources/OrderService";
const service = OrderService.build();

import FiscalOrderService from "@/services/resources/FiscalOrderService";
const serviceFiscalOrder = FiscalOrderService.build();

export default {
  props: ["dados"],
  mixins: [Money],
  components: {
    BaseModal,
  },
  data() {
    return {
      shipping_selected: null,
      jadlog: false,
      openGeration: false,
      selectedAgencia: null,
      optionsAgencia: [{ value: null, text: "Selecione uma Agencia" }],
      options_shipping: [{ value: null, text: "Selecione uma Agencia" }],
      codigoCNAE: "",
      notaFiscal: "",
      loading: false,
      step: 0,
      comercial: false,
      fiscal: false,
      selectIntegration: false,
      shipping: {
        address: {
          zipcode: "",
          street: "",
          number: "",
          complement: "",
          neighborhood: "",
          city: "",
          state: "",
        },
        service_name: "",
        service_id: "",
        amount: 0,
        old_amount: 0,
        frete: null,
      },
      nota_dados: {
        status: null,
      },
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YY HH:mm:ss");
    },
    checkFiscal() {
      this.loading = true;
      const sale_id = this.dados.sale_id;

      serviceFiscalOrder
        .read({
          id: `?sale_id=${sale_id}`,
        })
        .then((resp) => {
          this.nota_dados = resp;
          // this.nota_dados.status = "ISSUED";
          if (resp.status == "NOT_ISSUED") {
            this.selectIntegration = true;
            this.comercial = true;
            this.nota_dados.status = "NOT_ISSUED";
            // this.emitFiscal('NotaZZ');
          }
          if (resp.status == "PROCESSING") {
            this.selectIntegration = true;
            this.comercial = true;
            this.nota_dados.status = "PROCESSING";
            this.$bvToast.toast("Nota Fiscal sendo Processada!", {
              title: "Nota Fiscal!",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
          if (resp.status == "NOT_GENERATED") {
            this.openGeration = true;
            this.comercial = true;
            this.$bvToast.toast("Nota Fiscal ainda não foi Gerada!", {
              title: "Nota Fiscal!",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
          this.nota_dados.status = resp.status;
        })
        .catch((err) => {
          console.log(err);
          console.log(JSON.parse(err.response.data));
          if (err.response.data) {
            let response = JSON.parse(err.response.data);
            if (response.nota[0] === "Nota Fiscal não emitida.") {
              this.selectIntegration = true;
              this.nota_dados.status = "NOT_ISSUED";
            } else if (response.nota[0] === "Nota Fiscal não encontrada.") {
              this.selectIntegration = true;
              this.nota_dados.status = "NOT_ISSUED";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkStatusFiscal() {
      var sale_id = this.dados.sale_id;

      serviceFiscalOrder
        .read({
          id: `?sale_id=${sale_id}`,
        })
        .then((resp) => {
          console.log("checkStatusFiscal-->", resp);
          if (resp.chave) {
            this.notaFiscal = resp.chave;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    addFiscal() {
      this.comercial = true;
      this.fiscal = true;
      // this.checkFiscal();
    },
    emitFiscal(integration) {
      this.loading = true;
      var sale_id = this.dados.sale_id;

      serviceFiscalOrder
        .postID({
          id: `/issue`,
          sale_id: sale_id,
          integration: integration,
        })
        .then((resp) => {
          console.log("emitFiscal-->", resp);
          if (resp.status === "ISSUED") {
            this.notaFiscal = resp.key_note;
          } else if(resp.status === "PROCESSING"){
            this.$bvToast.toast("Nota fiscal esta sendo processada, aguarde!.", {
              title: "Nota Fiscal!",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.nota_dados.status = "PROCESSING";
          }else {
            this.checkStatusFiscal();
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        })
        .finally(() => {});
    },
    getAgencias(agency_id) {
      this.loading = true;

      this.optionsAgencia = [{ id: null, name: "Selecione uma Agencia" }];
      service
        .postID({
          id: `melhor_envio/agencies?order_id=${this.dados.id}&company_id=${agency_id}`,
        })
        .then((resp) => {
          console.log("167-->", resp);
          if (!resp) {
            return;
          }
          resp.forEach((element) => {
            this.optionsAgencia.push({
              id: element.id,
              name: element.company_name,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeFrete(frete) {
      console.log(frete);
      this.jadlog = false;
      if (frete.company.name === "Jadlog") {
        this.jadlog = true;
        this.getAgencias(frete.company.id);
      }
      this.shipping.frete = frete;
      if (this.shipping.old_amount) {
        this.amount = this.shipping.old_amount - this.shipping.amount;
      }
      this.shipping.amount = +frete.price;
      this.amount += this.shipping.amount;
      this.shipping.old_amount = this.amount;

      this.shipping.service_name = frete.name;
    },
    generateFiscal() {
      this.loading = true;
      serviceFiscalOrder
        .postID({
          id: `/generate/manual?sale_id=${this.dados.sale_id}`,
        })
        .then((resp) => {
          console.log("manual-->", resp);
          if(resp.nota || resp.status === "NOT_ISSUED"){
            this.$bvToast.toast("Nota fiscal gerada, por favor faça a emissão.", {
              title: "Nota Fiscal!",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.nota_dados.status = "NOT_ISSUED";
            this.addFiscal();
            this.selectIntegration = true;
            this.openGeration = false;
            return
          }

          if(resp.nota && resp.nota[0].status === 'PROCESSING'){
            this.$bvToast.toast("Nota Fiscal em processamento.!", {
              title: "Nota Fiscal!",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }

          if(resp.nota && resp.nota[0].status === 'NOT_ISSUED'){
            this.$bvToast.toast("Nota Fiscal gerada aguarde para ser emitida!", {
              title: "Nota Fiscal!",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.nota_dados.status = "NOT_ISSUED";
            this.addFiscal();
            this.selectIntegration = true;
            this.openGeration = false;
          }
        })
        .catch((err) => {
          console.log(err);
          // if (err.response.data) {
          //   let response = JSON.parse(err.response.data);
          //   if (response.nota[0] === "Nota fiscal já foi gerada, por favor faça a emissão.") {
          //     this.nota_dados.status = "NOT_ISSUED";
          //     this.addFiscal();
          //     this.selectIntegration = true;
          //     this.openGeration = false;
          //   }
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    gerarEtiqueta() {
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$bvToast.toast(
            "A geração da sua etiqueta foi iniciada. Por favor aguarde",
            {
              title: "Etiqueta",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );

          let data = {
            id: `ticket`,
            order_id: this.dados.id,
            non_commercial: !this.comercial,
            invoice_key: this.notaFiscal,
            economic_activity_code: this.codigoCNAE,
          };

          if (this.shipping_selected) {
            data.service_id = this.shipping_selected.frete.id;
            data.service_price = this.shipping_selected.amount;
          } else {
            data.service_id = this.shipping.frete.id;
            data.service_price = this.shipping.amount;
          }

          if (
            !this.shipping_selected &&
            this.shipping.frete.company.name === "Jadlog"
          ) {
            let agencias = this.optionsAgencia;
            let result = agencias.filter((x) => x.id === this.selectedAgencia);
            data.agency_id = result[0].id;
            data.agency_name = result[0].name;
          }

          if (
            this.shipping_selected &&
            this.shipping_selected.frete.company.name === "Jadlog"
          ) {
            data.agency_id = this.shipping_selected.service_id;
            data.agency_name = this.shipping_selected.service_name;
          }

          this.closeModal();

          service
            .postID(data)
            .then((resp) => {
              this.$bvToast.toast("Etiqueta gerado com sucesso!", {
                title: "Etiqueta!",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              window.open(resp.ticket_url, "_blank");

              this.$root.$emit("updateItem");
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              // this.loading = false;
            });
        } else {
          this.$bvToast.toast("Preencha os campos obrigátorios", {
            title: "Etiqueta",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      });
    },
    getRoutes() {
      this.loading = true;
      service
        .postID({
          id: `melhor_envio/calculate?order_id=${this.dados.id}`,
        })
        .then((resp) => {
          this.options_shipping = resp;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.options_shipping.length) {
            this.options_shipping = [...this.options_shipping].filter(
              (frete) => {
                if (frete.price || frete.error) {
                  return frete;
                }
              }
            );

            this.options_shipping = [...this.options_shipping].sort((a, b) => {
              return +a.price - +b.price;
            });

            // revalidando
            if (this.shipping.frete) {
              const frete_selecionado = this.options_shipping.find(
                (item) => item.id == this.shipping.service_id
              );

              if (+this.shipping.frete.price != +frete_selecionado.price) {
                this.$bvToast.toast(this.$t("Erro no cálculo do frete"), {
                  title: "Frete",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });

                return;
              } else {
                return;
              }
            } else {
              this.shipping.service_id = this.options_shipping[0].id;
              this.changeFrete(this.options_shipping[0]);
              return;
            }
          } else {
            return;
          }
        });
    },
    openModal() {
      console.log(this.dados);
      this.checkFiscal();
      this.loading = true;
      service
        .read({
          id: `/check-chosen-service/${this.dados.id}`,
        })
        .then((resp) => {
          if (resp.success) {
            this.shipping_selected = JSON.parse(this.dados.shipping_selected);
            if (this.shipping_selected.frete.company.name === "Jadlog") {
              this.jadlog = true;
              this.getAgencias(this.shipping_selected.frete.company.id);
            }
          } else {
            this.getRoutes();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$root.$emit("bv::hide::modal", "modal-ticket", "#btnShow");
      this.shipping_selected = null;
      this.comercial = false;
      this.fiscal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.set-fiscal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-envio-error {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.size-card-ml {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.custom-width-card {
  width: 154px;
  height: 103px;
}

.title-item {
  color: #2133d2;
}

.center-itens {
  display: flex;
  justify-content: center;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858e;
}

.img-title-integration {
  width: 15.43px;
  height: 17.14px;
}

.container-logo {
  display: grid;
  justify-content: center;
  img {
    // width: 100%;
    position: relative;
    top: 10px;
  }
}

.card-body {
  cursor: pointer;
}

.title-integration {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #333333;
}

.select-type {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  div {
    background-color: rgb(227, 227, 227);
    width: 100%;
    height: 40vh;
    cursor: pointer !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
  }
  div.active {
    background-color: rgb(85, 96, 255);
  }
}

// frete
.opcoes-frete {
  margin: 30px 0;
}
.opcoes-frete legend {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
}
.frete-item {
  padding: 15px;
  background: #f7f7f7;
  border-radius: 5px;
}
.frete-item + .frete-item {
  margin-top: 10px;
}
.frete-item img {
  max-width: 80px;
}
.frete-item .infos-frete {
  display: flex;
  align-items: center;
  width: 600px;
  padding: 0 10px;
  gap: 50px;
}
.frete-item .info-envio {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.info-envio .frete-valor {
  color: var(--main-color);
  font-weight: 600;
  text-align: right;
}
@media screen and (max-width: 769px) {
  .frete-item .infos-frete {
    width: 100%;
  }
  .frete-item .info-envio {
    grid-template-columns: 1fr;
  }
  .info-envio span {
    font-size: 13px;
  }
  .info-envio span + span {
    margin-top: 3px;
  }
  .info-envio .frete-valor {
    text-align: left;
    font-size: 15px;
  }
}
.size-card-smartnotas {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.custom-width-card {
  width: 154px;
  height: 103px;
}

.title-item {
  color: #2133d2;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858e;
}

.img-title-integration {
  width: 15.43px;
  height: 17.14px;
}

.container-logo {
  display: grid;
  justify-content: center;
}

.card-body {
  cursor: pointer;
}

.title-integration {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #333333;
}
</style>